import React, { useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import classNames from 'classnames';

import css from './SortByPlain.module.css';
import IconPlus from '../IconPlus/IconPlus';

const IconCheckbox = props => {
  const { isSelected, isHovered } = props;

  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.25"
        y="0.25"
        width="14.5"
        height="14.5"
        fill={isSelected ? "var(--colorBlack)" : "none"}
        stroke={isHovered ? isSelected ? "#ACACAC" : "var(--marketplaceColorDark)" : "#ACACAC"}
        strokeWidth="0.5"
      />
    </svg>
  );
};

const SortByPlain = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { rootClassName, className, label, options, initialValue, positionAside } = props;

  const selectOption = (option, e) => {
    const { urlParam, onSelect } = props;
    onSelect(urlParam, option);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  };

  const toggleIsOpen = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const labelClass = initialValue ? css.filterLabelSelected : css.filterLabel;

  const optionsContainerClass = classNames({
    [css.optionsContainerOpen]: isOpen,
    [css.optionsContainerClosed]: !isOpen,
  });

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={labelClass}>
        <button
          className={css.labelButton}
          onClick={toggleIsOpen}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <span className={css.labelButtonContent}>
            <span className={css.labelWrapper}>
              <span className={labelClass}>
                {label}
              </span>
            </span>
            <span className={css.openSign}>
              <IconPlus isOpen={isOpen} isHovered={isHovered} isSelected={initialValue} />
            </span>
          </span>
        </button>
      </div>
      <div className={optionsContainerClass}>
        {options.map(option => {
          // check if this option is selected
          const [optionHovered, setOptionHovered] = useState(false);

          const selected = initialValue === option.key;

          const isClickable = !selected;
          const cursorMaybe = isClickable ? { cursor: 'pointer' } : { cursor: 'default' };

          const optionClass = selected ? css.optionSelected : css.option;
          // menu item selected or border class
          const optionBorderClass = classNames({
            [css.optionBorderSelected]: selected,
            [css.optionBorder]: !selected,
          });
          return (
            <button
              key={option.key}
              className={optionClass}
              disabled={option.disabled}
              onClick={() => (selected ? null : selectOption(option.key))}
              onMouseEnter={() => setOptionHovered(true)}
              onMouseLeave={() => setOptionHovered(false)}
              style={{ ...cursorMaybe }}
            >
              <IconCheckbox isSelected={selected} isHovered={optionHovered} />
              {!positionAside && <span className={optionBorderClass} />}
              {option.longLabel || option.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

SortByPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValue: null,
};

SortByPlain.propTypes = {
  rootClassName: string,
  className: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
};

export default SortByPlain;
